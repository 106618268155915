
.btn-hover-zoom {
    transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
}
  
  .btn-hover-zoom:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}