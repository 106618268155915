.bg-image {
    background-image: url('/public/assets/images/19326.jpg');  
    background-size:cover ;
    background-repeat: no-repeat;
    background-position: center; 
    background-attachment: fixed; 
    height: 80vh;
    padding: 50px;
    color: white;
  } 
    
   @media (max-width: 767px) {
    .bg-image {
      padding: 30px; 
    }
  } 
   

   .btn-hover-zoom {
    transition: transform 0.3s ease-in-out;
    -webkit-transition: transform 0.3s ease-in-out;
    -moz-transition: transform 0.3s ease-in-out;
    -ms-transition: transform 0.3s ease-in-out;
    -o-transition: transform 0.3s ease-in-out;
}
  
  .btn-hover-zoom:hover {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
}

.styled-card1{
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.styled-card1:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px  rgba(0, 174, 255, 0.5);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
.styled-card2{
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.styled-card2:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px  rgba(10, 165, 28, 0.5);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
.styled-card3{
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  -webkit-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -moz-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -ms-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  -o-transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  cursor: pointer;
}

.styled-card3:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px  rgba(225, 21, 202, 0.5);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
.styled-card4{
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.styled-card4:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px  rgba(246, 252, 73, 0.5);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
.styled-card5{
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.styled-card5:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px  rgba(221, 9, 9, 0.5);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}
.styled-card6{
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.styled-card6:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 24px  rgba(34, 0, 255, 0.5);
  -webkit-transform: translateY(-10px);
  -moz-transform: translateY(-10px);
  -ms-transform: translateY(-10px);
  -o-transform: translateY(-10px);
}

.mon-image {
  max-width: 600px;
  height: auto; /* Pour conserver les proportions de l'image */
}

/* h1{
  text-shadow: 2px 2px 4px rgba(255, 193, 7, 0.4);
} */

h1, h2{
  font-family: 'Lucida Handwriting';
}
h3 {
  font-family: 'Oleo Script Swash Caps';
  
}

/* .warning-underline {
  text-decoration: underline;
  text-decoration-color: #FFC107;
  -moz-text-decoration-color: #FFC107;
} */
 