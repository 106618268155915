.underline {
    text-decoration: underline;
}

@media (max-width: 767px) {
   
    .content {
      text-align:center;
    }
  }
  