.nav-link-hover {
    text-decoration: none; /* Pas de soulignement par défaut */
    position: relative;
  }
  
  .nav-link-hover::after {
    content: '';
    position: absolute;
    width: 0;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: white; /* Couleur de l'underline */
    transition: width 0.3s;
  }
  
  .nav-link-hover:hover::after {
    width: 100%; /* L'underline apparaît au survol */
  }

  .name{
    font-family: 'Lucida Handwriting';
  }

  
