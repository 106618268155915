.custom-card {
  width: 100%;
  height: 100%; 
  display: flex;
  flex-direction: column; 
}

.custom-card .card-body {
  flex-grow: 1;
  display: flex; 
  flex-direction: column;
}

.custom-media-overlay {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1; 
  object-fit: cover; 
}

.custom-overlay-content {
  position: absolute;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5); /* Ajoute un fond semi-transparent */
  width: 100%;
  text-align: center;
}

