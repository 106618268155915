.App {
  text-align: center;
  margin-top: 500px;
}


@media (max-width: 571px) {
  /* Appliquer le margin-top à partir de 768px de largeur d'écran */
  .main-content {
    margin-top: 80px; /* Ajuste en fonction de la hauteur de ta barre de navigation */
  }
}

